<template>
  <div
    class="relative grid grid-cols-[1fr_auto] items-center whitespace-nowrap text-base leading-none text-white first:!border-none"
  >
    <NuxtLink
      to="/wallet"
      class="flex items-center gap-4 px-5 py-4 !opacity-100"
      @click="handleNavigatorClick"
    >
      <div class="rounded-full bg-white/5 p-1">
        <!-- <Icon class="text-sm" icon="uit:wallet" /> -->
        <img class="aspect-square w-[14px] rounded-full" :src="wallet.logo" :alt="wallet.name" />
      </div>
      <div class="flex flex-col items-start gap-1">
        <span
          class="larken text-sm leading-none"
          :class="wallet.name.toLowerCase() === 'xdefi' && 'uppercase'"
        >
          {{ wallet.name }}
        </span>
        <div
          class="grid grid-cols-[auto_1fr] items-baseline justify-items-start gap-1 text-[10px] leading-none text-white/70"
        >
          <div
            class="visible col-start-1 row-start-1 aspect-square w-1.5 rounded-full border border-solid border-[#33FF9959] bg-[#33FF99]"
          />
          <span class="visible col-start-2 row-start-1 opacity-50">Connected</span>
          <span class="invisible col-span-2 col-start-1 row-start-1"> View Assets/Earnings </span>
        </div>
      </div>
    </NuxtLink>
    <button
      class="flex h-full items-center px-5 py-4 text-[#E84142] hover:bg-[#341b1b]"
      @click="
        () => {
          disconnectWallet(wallet.type)
        }
      "
    >
      <Icon class="text-sm" icon="fluent:plug-disconnected-24-filled" />
    </button>
    <div class="col-span-2 flex flex-wrap items-center justify-between gap-3 px-5 pb-4 pt-1">
      <ConnectedChainItem
        v-for="(chain, index) in visibleChains"
        :key="index"
        :logo="chain.logo"
        :chain-name="chain.chain"
        :address="chain.address"
      />
      <button
        v-if="wallet.chains.length > maxVisibleCountConnectedChains"
        class="ml-auto text-xs text-white/50"
        @click="toggleConnectedChains"
      >
        <template v-if="maxVisibleCountConnectedChains > 0">
          <span class="font-medium text-[#33FF99]"
            >+ {{ wallet.chains.length - maxVisibleCountConnectedChains }}</span
          >
          <span class="font-medium text-[#33FF99]/50"> more </span>
        </template>
        <template v-else>
          <div class="flex items-center text-white/50">
            Collapse
            <Icon icon="icon-park-outline:up" />
          </div>
        </template>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed, ref } from 'vue'
import ConnectedChainItem from './ConnectedChainItem.vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const props = defineProps<{ wallet: any }>()

const emit = defineEmits<{
  collapse: []
}>()

const maxVisibleCountConnectedChains = ref(5)
const toggleConnectedChains = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()
  if (maxVisibleCountConnectedChains.value > 0) {
    maxVisibleCountConnectedChains.value = 0
  } else {
    maxVisibleCountConnectedChains.value = 5
  }
}

const visibleChains = computed(() =>
  maxVisibleCountConnectedChains.value
    ? props.wallet.chains.slice(0, maxVisibleCountConnectedChains.value)
    : props.wallet.chains,
)

// #region composables
const swapkitWalletStore = useSwapkitWalletStore()
const { disconnectWallet } = swapkitWalletStore

const handleNavigatorClick = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
  emit('collapse')
}

// #endregion
</script>
